import React from "react";
import { PageContainer, InnerContentContainer } from "../ui-components";
import Typography from "@mui/material/Typography";
import { uiConfig } from "../../utils/firebase";
import APP_CONFIG from "../../utils/config";
import { StyledFirebaseAuth } from "react-firebaseui";
import { getAuth } from "@firebase/auth";

const LoginPage: React.FC = () => {
  return (
    <PageContainer>
      <InnerContentContainer
        sx={{
          flexDirection: "column",
          padding: "1rem",
        }}
      >
        <Typography variant="h6" component="h1">
          {`Welcome to ${APP_CONFIG.APP_NAME}`}
        </Typography>
        <Typography variant="subtitle1">Please log in</Typography>

        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth()} />
      </InnerContentContainer>
    </PageContainer>
  );
};

export default LoginPage;
