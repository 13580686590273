import FirstPage from "../components/FirstPage";
import Home from "../components/Home";
import ProfilePage from "../components/ProfilePage";
import SignupForm from "../components/SignupForm";

export interface NavItemElement {
  label: string;
  path: string;
  Component: React.FC;
}

export enum NavItem {
  Home,
  FirstPage,
  Profile,
  Signup,
}

export const navItemOrder = [
  NavItem.Signup,
  NavItem.FirstPage,
  NavItem.Profile,
  NavItem.Home,
];

const navItems: Record<string, NavItemElement> = {
  [NavItem.Profile]: {
    label: "Profile",
    path: "/profile",
    Component: ProfilePage,
  },
  [NavItem.Home]: {
    label: "Home",
    path: "/",
    Component: Home,
  },
  [NavItem.FirstPage]: {
    label: "First Page",
    path: "/first-page",
    Component: FirstPage,
  },
  [NavItem.Signup]: {
    label: "Signup Form",
    path: "/signup",
    Component: SignupForm,
  },
};

export default navItems;
