import React, { useState, Profiler } from "react";
import { Control, Controller, useForm } from "react-hook-form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  Button,
  MenuItem,
  Grid,
  Slider as MuiSlider,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import questions, {
  MultiCheckbox,
  MultiSliders,
  MultiTextInput,
  Questions,
  questionsOrder,
  QuestionType,
  SingleSelect,
  SingleSlider,
  SliderConfig,
  SliderType,
} from "./questions";
import { reactProfiler } from "../../utils";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement<unknown, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export interface SliderProps {
  questionKey: string;
  config: SliderConfig;
  control: Control;
}

const Slider: React.FC<SliderProps> = ({
  control,
  questionKey,
  config: { marks, type, defaultRange = [], label, discreetMarks },
}) => {
  const defaultValue =
    type === SliderType.Percent
      ? marks.findIndex((s) => s === defaultRange[0])
      : defaultRange.map((m) => marks.findIndex((s) => s === m));

  const [sliderValue, setSliderValue] = useState<number[] | number>(
    defaultValue
  );
  return (
    <Controller
      control={control}
      name={questionKey}
      defaultValue={defaultValue}
      render={({ field }) => (
        <>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {label}
          </Typography>
          <MuiSlider
            id={questionKey}
            marks={marks.map((l, i) => ({
              label: l,
              value: i,
            }))}
            key={questionKey}
            max={marks.length - 1}
            min={0}
            sx={
              discreetMarks
                ? {
                    "& .MuiSlider-markLabel": {
                      display: "none",
                    },

                    marginBottom: "0",
                  }
                : {}
            }
            {...field}
            onChange={(e, v) => {
              setSliderValue(v);
              field.onChange(e, v);
            }}
          />
          {discreetMarks && (
            <Typography>
              {marks[Array.isArray(sliderValue) ? sliderValue[1] : sliderValue]}
            </Typography>
          )}
        </>
      )}
    />
  );
};

export interface QuestionDialogProps {
  questionKey: Questions;
  setCurrentQuestion: (nextQuestionKey: Questions) => void;
  control: Control;
  onComplete: () => void;
  open: boolean;
}
const QuestionDialog: React.FC<QuestionDialogProps> = ({
  questionKey,
  setCurrentQuestion,
  onComplete,
  control,
  open,
}) => {
  const question = questions[questionKey];
  const questionIdx = questionsOrder.findIndex((q) => q === questionKey);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      key={questionKey}
      TransitionComponent={Transition}
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
        {question.label}
      </DialogTitle>
      <DialogContent
        style={{
          padding: "1rem 3rem",
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "center",
        }}
      >
        {(() => {
          if (question.type === QuestionType.MultiTextInput) {
            const { inputs } = question as MultiTextInput;

            return (
              <Grid container direction="column" rowSpacing={2}>
                {inputs.map(({ label, maxLength, key }) => (
                  <Grid item key={`${questionKey}.${key}`}>
                    <Controller
                      name={`${questionKey}.${key}`}
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={label}
                          multiline={maxLength > 100}
                          rows={
                            maxLength > 100
                              ? Math.ceil(maxLength / 100)
                              : undefined
                          }
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                ))}
              </Grid>
            );
          }
          if (question.type === QuestionType.SingleSelect) {
            const { options, selectLabel, defaultOption } =
              question as SingleSelect;
            return (
              <Controller
                control={control}
                name={questionKey}
                defaultValue={defaultOption || options[0]}
                render={({ field }) => (
                  <TextField
                    select
                    label={selectLabel}
                    SelectProps={{ inputProps: field }}
                  >
                    {options.map((o) => (
                      <MenuItem value={o} key={o}>
                        {o}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            );
          }

          if (question.type === QuestionType.SingleSlider) {
            const { slider } = question as SingleSlider;
            return (
              <Slider
                config={slider}
                control={control}
                questionKey={questionKey}
              />
            );
          }

          if (question.type === QuestionType.MultiSliders) {
            const { sliders } = question as MultiSliders;
            return (
              <Grid container direction={"column"} rowSpacing={2}>
                {sliders.map((s) => (
                  <Grid
                    item
                    key={s.key}
                    sx={{ position: "relative", width: "100%" }}
                  >
                    <Slider
                      config={s}
                      control={control}
                      questionKey={`${questionKey}.${s.key}`}
                    />
                  </Grid>
                ))}
              </Grid>
            );
          }

          if (question.type === QuestionType.MultiCheckbox) {
            const { options } = question as MultiCheckbox;

            return (
              <FormGroup>
                {options.map(({ optionKey, label }) => (
                  <Controller
                    name={`${questionKey}.${optionKey}`}
                    control={control}
                    key={`${questionKey}.${optionKey}`}
                    defaultValue={false}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <FormControlLabel
                        label={label}
                        control={
                          <Checkbox
                            onChange={onChange}
                            onBlur={onBlur}
                            checked={value}
                            inputRef={ref}
                          />
                        }
                      />
                    )}
                  />
                ))}
              </FormGroup>
            );
          }

          return (
            <Typography>{`Question type ${question.type} not implemented`}</Typography>
          );
        })()}
      </DialogContent>
      <DialogActions sx={{ padding: "1rem" }}>
        {questionIdx < questionsOrder.length && (
          <Button
            color="error"
            variant="text"
            onClick={() =>
              setCurrentQuestion(
                questionsOrder[questionIdx === 0 ? 0 : questionIdx - 1]
              )
            }
          >
            Back
          </Button>
        )}
        {questionIdx < questionsOrder.length - 1 && (
          <Button
            onClick={() => setCurrentQuestion(questionsOrder[questionIdx + 1])}
          >
            Next
          </Button>
        )}
        {questionIdx === questionsOrder.length - 1 && (
          <Button
            type="submit"
            color="success"
            variant="contained"
            onClick={onComplete}
          >
            Done
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const SignupForm: React.FC = ({}) => {
  const [currentQuestion, setCurrentQuestion] = useState<Questions>(
    questionsOrder[0]
  );

  const { handleSubmit, control, formState } = useForm();
  const [formResponse, setFormResponse] = useState<any>(null);
  const [dialogOpen, setDialogOpen] = useState(true);

  const onComplete = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    setFormResponse(data);
    setDialogOpen(false);
  };

  return (
    <Profiler id="SignupForm" onRender={reactProfiler}>
      <QuestionDialog
        questionKey={currentQuestion}
        setCurrentQuestion={setCurrentQuestion}
        control={control}
        onComplete={handleSubmit(onComplete)}
        open={dialogOpen}
      />
      {formState.isSubmitted && (
        <Typography component={"pre"} sx={{ display: "block", margin: "1rem" }}>
          {JSON.stringify(formResponse, null, 2)}
        </Typography>
      )}
    </Profiler>
  );
};

export default SignupForm;
