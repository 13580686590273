// import { ObjectCanon } from "@apollo/client/cache/inmemory/object-canon";
import { ProfilerOnRenderCallback } from "react";

export const reactProfiler: ProfilerOnRenderCallback = (
  id: string, // the "id" prop of the Profiler tree that has just committed
  ...args
) => {
  console.groupCollapsed(`${id} (${args[0]})`);
  [
    "phase",
    "actualDuration",
    "baseDuration",
    "startTime",
    "commitTime",
    "interactions",
  ].forEach((c, i) => console.log(`${c}: ${args[i]}`));
  console.groupEnd();
};

export const templateStringReplacer = (
  originalString: string,
  dataObject: any
): string => {
  return originalString.replace(/{{\s?(.*?)\s?}}/g, (match, offset, idx) => {
    if (offset in dataObject) {
      return Array.isArray(dataObject[offset])
        ? dataObject[offset].join(", ")
        : dataObject[offset];
    }
    return match;
  });
};
