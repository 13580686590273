import { Slider } from "@mui/material";
import moment from "moment-timezone";

export enum QuestionType {
  SingleSelect = "SingleSelect",
  MultiSelect = "MultiSelect",
  MultiSliders = "MultiSliders",
  SingleSlider = "SingleSlider",
  MultiTextInput = "MultiTextInput",
  MultiCheckbox = "MultiCheckbox",
}

export interface SliderConfig<StepType = string> {
  type: SliderType;
  label: string;
  key: string;
  marks: StepType[];
  discreetMarks?: boolean;
  isRange?: boolean;
  defaultRange?: [StepType, StepType];
}

export interface BaseQuestion {
  label: string;
  type: QuestionType;
}

export interface SingleSelect<OptionType = string> extends BaseQuestion {
  options: OptionType[];
  selectLabel: string;
  defaultOption?: OptionType;
}

export interface MultiSliders extends BaseQuestion {
  sliders: SliderConfig[];
}
export interface SingleSlider extends BaseQuestion {
  slider: SliderConfig;
}

export interface MultiCheckbox extends BaseQuestion {
  options: { optionKey: string; label: string }[];
}

export interface MultiTextInput extends BaseQuestion {
  inputs: {
    label: string;
    maxLength: number;
    key: string;
  }[];
}

export enum SliderType {
  Range,
  SinglePoint,
  Percent,
}

export enum Questions {
  Age = "age",
  Location = "location",
  Triggers = "triggers",
  MovieRating = "movieRating",
  Triggers2 = "triggers2",
  ExperienceTime = "experienceTime",
  ExperiencePlatforms = "experiencePlatforms",
  PartySize = "partySize",
  SelfDescription = "selfDescription",
  SocialLinks = "socialLinks",
}

export const questionsOrder = [
  Questions.Age,
  Questions.Location,
  Questions.Triggers,
  Questions.Triggers2,
  Questions.MovieRating,
  Questions.ExperienceTime,
  Questions.ExperiencePlatforms,
  Questions.PartySize,
  Questions.SelfDescription,
  Questions.SocialLinks,
];

const questions: Record<Questions, BaseQuestion & any> = {
  [Questions.Age]: {
    label: "What is your age?",
    type: QuestionType.SingleSelect,
    selectLabel: "Age",
    defaultOption: "19 - 25",
    options: [
      "13 - 18",
      "19 - 25",
      "26 - 35",
      "36 - 45",
      "46 - 55",
      "56 - 65",
      "65+",
    ],
  },
  [Questions.Location]: {
    label: "What timezone are you in?",
    type: QuestionType.SingleSelect,
    defaultOption: moment.tz.guess(),
    options: moment.tz.names(),
  },
  [Questions.Triggers]: {
    label:
      "Rate your comfort level with these topics so DMs know what to avoid during gameplay",
    type: QuestionType.MultiSliders,
    sliders: [
      {
        type: SliderType.Percent,
        label: "Homophobia",
        key: "homophobia",
        marks: ["Not OK", "Need to discuss", "OK"],
        defaultRange: ["OK"],
      },
      {
        type: SliderType.Percent,
        label: "Sexism",
        key: "sexism",
        marks: ["Not OK", "Need to discuss", "OK"],
        defaultRange: ["OK"],
      },
      {
        type: SliderType.Percent,
        label: "Transphobia",
        key: "transphobia",
        marks: ["Not OK", "Need to discuss", "OK"],
        defaultRange: ["OK"],
      },
      {
        type: SliderType.Percent,
        label: "Real-world religion",
        key: "religion",
        marks: ["Not OK", "Need to discuss", "OK"],
        defaultRange: ["OK"],
      },
      {
        type: SliderType.Percent,
        label: "Racism",
        key: "racism",
        marks: ["Not OK", "Need to discuss", "OK"],
        defaultRange: ["OK"],
      },
      {
        type: SliderType.Percent,
        label: "Ableism",
        key: "ableism",
        marks: ["Not OK", "Need to discuss", "OK"],
        defaultRange: ["OK"],
      },
      {
        type: SliderType.Percent,
        label: "Health conditions (cancer, stroke, mental illness)",
        key: "healthConditions",
        marks: ["Not OK", "Need to discuss", "OK"],
        defaultRange: ["OK"],
      },
      {
        type: SliderType.Percent,
        label: "Permanent Death",
        key: "death",
        marks: ["Not OK", "Need to discuss", "OK"],
        defaultRange: ["OK"],
      },
    ],
  },
  [Questions.MovieRating]: {
    label: "If this game was a movie, what rating would like to play at?",
    type: QuestionType.SingleSlider,
    slider: {
      type: SliderType.Range,
      marks: ["G", "PG-13", "R", "Unrated"],
      defaultRange: ["G", "Unrated"],
    },
  },
  [Questions.Triggers2]: {
    label: "What is your comfort level with these topics?",
    type: QuestionType.MultiSliders,
    sliders: [
      {
        type: SliderType.Percent,
        label: "Horror",
        key: "horror",
        discreetMarks: true,
        marks: [
          "No horror",
          "Bugs / Cringe",
          "Some Blood / Violence",
          "Harm to Children / Animals",
          "Extreme Gore",
        ],
        defaultRange: ["No horror", "Extreme Gore"],
      },
      {
        type: SliderType.Percent,
        label: "Romance / Relationships",
        key: "romance",
        discreetMarks: true,
        marks: [
          "Light Romance between NPCs",
          "Romance between NPCs and PCs",
          "Fade-to-black sex",
          "Descriptive sex",
          "Extreme Sexuality",
        ],
        defaultRange: ["Light Romance between NPCs", "Extreme Sexuality"],
      },
    ],
  },
  [Questions.ExperienceTime]: {
    label: "How long have you been playing DnD or other tabletop games?",
    type: QuestionType.SingleSelect,
    options: [
      "0 - 6 months",
      "6 months - 1 year",
      "1 - 3 years",
      "3 - 5 years",
      "5 - 10 years",
      "10+ years",
    ],
  },
  [Questions.ExperiencePlatforms]: {
    label: "Which online TTRPG programs have you used before",
    type: QuestionType.MultiCheckbox,
    options: [
      { optionKey: "none", label: "I haven't played TTRPG online before" },
      { optionKey: "discord", label: "Discord" },
      { optionKey: "forge", label: "Forge" },
      { optionKey: "roll20", label: "Roll20" },
      { optionKey: "fantasyGrounds", label: "Fantasy Grounds" },
      { optionKey: "roleGate", label: "RoleGate" },
    ],
  },
  [Questions.PartySize]: {
    label: "What size of party are you looking for?",
    type: QuestionType.SingleSlider,
    slider: {
      type: SliderType.Range,
      label: "Number of players (not including GM)",
      marks: Array(10)
        .fill(null)
        .map((_, i) => i + 1),
      defaultRange: [1, 10],
    },
  },
  [Questions.SelfDescription]: {
    label: "Tell us about yourself",
    type: QuestionType.MultiTextInput,
    inputs: [
      {
        key: "selfDescription",
        label: "Short description of yourself",
        maxLength: 360,
      },
      {
        key: "favoriteClassAndCharacter",
        label: "What is your favorite class and character you've played?",
        maxLength: 180,
      },
    ],
  },
  [Questions.SocialLinks]: {
    label: "Where else can we find you?",
    type: QuestionType.MultiTextInput,
    inputs: ["Discord", "Reddit", "Instagram", "Twitter", "Facebook"].map(
      (label) => ({ label, maxLength: 100, key: label.toLowerCase() })
    ),
  },
};

export default questions;
