import firebase from "firebase/compat/app";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, EmailAuthProvider } from "firebase/auth";
import { firebaseConfig } from "./config";

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const uiConfig = {
  signInFlow: "popup",
  signInSuccessUrl: "/",
  signInOptions: [
    {
      provider: EmailAuthProvider.PROVIDER_ID,
      fullLabel: "Log in with email",
    },
    {
      provider: GoogleAuthProvider.PROVIDER_ID,
      fullLabel: "Log in with Google",
    },
  ],
};

export const analytics = getAnalytics(firebaseApp);

export default firebaseApp;
