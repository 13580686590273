import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontSize: 14,
  },
  palette: {
    primary: { main: "#2A2D34" },
    secondary: { main: "#009DDC" },
    info: { main: "#6761A8" },
    success: { main: "#009B72" },
    error: { main: "#F26430" },
  },
  components: {
    MuiSlider: {
      defaultProps: {
        color: "secondary",
      },
      styleOverrides: {
        root: {
          padding: "0.5rem 0 !important",
        },
        markLabel: {
          top: "20px !important",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "outlined",
      },
    },
  },
});

export default theme;
